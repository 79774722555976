<template>
    <b-form-group :invalid-feedback="invalidFeedback" :label="label" :label-for="formControlId" v-bind="$attrs">
        <csp-form-select-searchable
            :id="formControlId"
            v-model="data[property]"
            :allow-empty="!isRequiredProperty"
            :disable-change-trigger="disableChangeTrigger"
            :disabled="isReadOnlyProperty"
            :options="options"
            :placeholder="placeholder"
            :state="validationState"
            :value-empty="valueEmpty"
            @change="handleInput"/>
    </b-form-group>
</template>

<script>
    import FormGroupMixin from './FormGroupMixin';
    import SelectSearchable from './SelectSearchable';

    export default {
        components: {
            'csp-form-select-searchable': SelectSearchable,
        },
        mixins: [FormGroupMixin],
        props: {
            options: {
                type: Array,
                default: () => [],
            },
            valueEmpty: {
                default: null,
            },
            disableChangeTrigger: {
                type: Boolean,
                default: false,
            },
        },
        emits: ['change'],
        computed: {
            placeholder() {
                if (this.hideLabel) {
                    return this.labelTranslation;
                }
                return this.$t('form.multiselect.placeholder');
            },
        },
        methods: {
            handleInput() {
                this.$emit('change');
            },
        },
    }
</script>
